<template>
    <div class="whyus">
        <h3 class="title"> چرا صرافی مارا انتخاب کنید ؟ </h3>
        <div class="bannerpic">
                <div class="text-container">    
                    <div class="texts t1">
                        <span class="feature-title"> امنیت کیف پول ها </span>
                        <p class="feature-text"> تیم امنیتی پیشرفته لیوتیرکس با ابزارهای مدرن و به‌روز، همواره برای حفظ امنیت دارایی کاربران تلاش می‌کنند. ذخیره امن دارایی کاربران در کیف پول‌های سرد و تایید هویت دو عاملی از جمله این ابزارها هستند.</p>
                    </div>
                    <div class="texts t2">
                        <span class="feature-title"> تنوع رمز ارز های بازار </span>
                        <p class="feature-text">معامله‌ی بهترین کوین‌های بازار در سه پایه بازار متفاوت و متنوع‌تر شدن سبد معاملات، یک ویژگی مهم برای کاربران حرفه‌ای است.</p>
                    </div>
                    <div class="texts t3">
                        <span class="feature-title"> اعتماد کاربران </span>
                        <p class="feature-text">پشتیبانی حرفه‌ای و ۲۴ ساعته‌ی آنلاین و تلفنی لیوتیرکس خیال کاربران را برای پاسخ به هر سوالی آسوده می‌کند.</p>
                    </div>
                    <div class="texts t4">
                        <span class="feature-title"> نقدینگی بازار </span>
                        <p class="feature-text">دارایی‌های شما در کیف‌پول اختصاصی به صورت سرد نگه‌داری می‌شود و دربرابر حمله‌های مختلف امنیت دارد.</p>
                    </div>
                </div>
                <img class="banner-img" src="../../assets/whyUs.svg" />
            </div>
    </div>
</template>
<script>
export default {
    name:'WhyUs',
}
</script>
<style lang="scss" scoped>
.text-container{
    height: 100%;
    right: -20px;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    width: 80%;
    .t1{
        transform: translate(60px,8px);
    }
    .t2{
        transform: translate(265px,-40px);
    }
    .t3{
        transform: translate(230px,-35px);
    }
    .t4{
        transform: translate(30px,-85px);
    }
    .feature-title , .feature-text{
        background: var(--home-background);
        border-radius: 10px;
       
    }
    .feature-text{
         color: var(--grey);
         font-size: clamp(12px,1vw,14px);
    }
    .feature-title{
        font-size: clamp(14px,2vw,18px);
         color: var(--dark-blue);
    }
}
.title{
    color: var(--dark-blue);
    font-size: clamp(20px,3vw,24px);
}
.whyus{
    // width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 50px;

}
.bannerpic{
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: flex-end;
    justify-content: flex-end;
    align-self: flex-end;
}


@media only screen and(max-width:800px){
    .banner-img{
        display: none;
    }
    .text-container{
        position: unset !important;
        justify-content: space-between;
        width: 100%;
        row-gap: 40px;
    }
    .bannerpic{
        padding: 50px 0;
    }
    .texts{
        transform: unset !important;
        display: flex;
        width: 100%;
        flex-direction: column;
        text-align: center;
        .feature-text{
         color: var(--grey);
         font-size: clamp(14px,1vw,18px);
        }
        .feature-title{
            font-size: clamp(18px,2vw,22px);
            color: var(--dark-blue);
        }
    }
}
</style>