<template>
    <div class="carusel-container">
        <Slider :key="blogs.length" :content.sync="blogs"/>
    </div>
</template>

<script>
import Slider from '../Tools/Slider.vue';

export default {
    name:'CaruselHomePage',
    components:{
        Slider
    },
    data() {
        return {
            blogs:[],      
        }
    },
    methods: {
        async getNews() {
          this.state.loading=true;
            const res = await fetch('https://aviinex.com/blog/wp-json/wp/v2/posts?per_page=8&context=embed&page=1').then(response => response.json()).then(data => this.news=data);
            this.blogs=res.filter((a,index) => index!==0 )
           this.state.loading=false;
        }
    },
    mounted() {
        this.getNews();
    },
}

</script>

<style lang="scss" scoped>
.slider-img{
    opacity: .7;
}
.content{
    width: 100%;
    background: var(--table-background);
    padding: 10px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    
}
.carusel-container{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 400px;
    background: var(--light-blue);
}
.slide{
    display: flex;
    flex-direction: column;
}
</style>